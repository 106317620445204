<template>
<div>
  <router-link :to="{name: 'BookstoreDetails', params: {id: res.id, props: res}}">
    <div class="book-card">
      <div class="book-cover-container">
        <div class="book-cover"
        v-for="(item, id) in 1" :key="id">
          <img
            :src="res.img_url ? (plainUrl + res.img_url) : (coverUrl + 'no_cover.png')"
          />
        </div>
        <!-- <div class="bottom-plus-sign"><i class="fas fa-plus-circle"></i></div> -->
      </div>
      <div class="mb-3">
        <a href="" class="book-title">{{ res.name }}</a>
        <span class="book-info"></span>
      </div>
    </div>
  </router-link>
</div>
</template>
<script>
export default {
  name: 'SearchResultListBook',
  props: {
    res: [Array, Object]
  },
  created () {
  }
}
</script>
<style lang="scss" scoped>
</style>
