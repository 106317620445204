<template>
<div>
  <router-link :to="{name: 'OtherUserProfile', params: {id: res.id}}" class="link-avatar">
    <div class="book-card">
      <div class="rowd-flex justify-content-around align-items-center">
        <div class="col">
          <div class="avatar">
              <img :src="res.thumb_url ? plainUrl + res.thumb_url : require('../assets/avatar-placeholder.jpg')" :alt="res.username" class="notification-avatar"/>
              <div @click.prevent="openModal" v-if="res.is_friend === null" class="bottom-add-sign"></div>
              <div @click.prevent="removeFriendModal" v-else class="bottom-remove-sign"></div>
          </div>
        </div>
            <div class="notification-content col">
              <div class="notification-content-header row">
                <div class="col-12">
                  <div class="mt-3 mb-4 top-heading-small text-center">{{res.username}}</div>
                </div>
              </div>
            </div>
      </div>
    </div>
  </router-link>
    <ModalSlot title="Dodaj książkę" v-if="isPopupShown" @close-modal="closeModal(false)">
    <div class="text-center mb-4 modal-main-title">Czy chcesz dodać {{res.username}} do listy znajomych?</div>
    <div class="d-flex justify-content-center align-items-center">
          <div class="avatar" v-if="res.thumb_url">
              <img :src="plainUrl + res.thumb_url" class="notification-avatar"/>
          </div>
          <div class="avatar-placeholder" v-else><i class="fas fa-user"></i></div>
    </div>
    <button class="btn-black-border w-100 mt-3 mb-1" @click="addFriend(res.id)">Dodaj</button>
    <button class="btn-black-full w-100 mt-3 mb-1" @click="closeModal(false)">Anuluj</button>
  </ModalSlot>
    <ModalSlot title="Dodaj książkę" v-if="isModalRemove" @close-modal="closeModal(false)">
    <div class="text-center mb-4 modal-main-title">Czy chcesz usunąć {{res.username}} z listy znajomych?</div>
    <div class="d-flex justify-content-center align-items-center">
          <div class="avatar" v-if="res.thumb_url">
              <img :src="plainUrl + res.thumb_url" class="notification-avatar"/>
          </div>
          <div class="avatar-placeholder" v-else><i class="fas fa-user"></i></div>
    </div>
    <button class="btn-black-border w-100 mt-3 mb-1" @click="removeFriend(res.id)">Usuń</button>
    <button class="btn-black-full w-100 mt-3 mb-1" @click="closeModal(false)">Anuluj</button>
  </ModalSlot>
    <ModalSlot title="Dodaj książkę" v-if="isAddedFriend" @close-modal="closeModal(false)">
      <div class="text-center mb-4 modal-main-title">Dodawanie użytkownika {{res.username}}</div>
      <div class="d-flex justify-content-center align-items-center"><img src="../assets/loaders/loader_on_overlay.gif" alt="loader" /></div>
  </ModalSlot>
    <ModalSlot title="Dodaj książkę" v-if="isRemovedFriend" @close-modal="closeModal(false)">
      <div class="text-center mb-4 modal-main-title">Usuwanie użytkownika {{res.username}}</div>
      <div class="d-flex justify-content-center align-items-center"><img src="../assets/loaders/loader_on_overlay.gif" alt="loader" /></div>
  </ModalSlot>
</div>
</template>
<script>
import ModalSlot from '../components/ModalSlot'
export default {
  name: 'SearchResultListBook',
  components: {
    ModalSlot
  },
  props: {
    res: Object
  },
  computed: {
    my_id () { return this.$store.state.userData.userId }
  },
  data () {
    return {
      isPopupShown: false,
      isModalRemove: false,
      isAddedFriend: false,
      isRemovedFriend: false
    }
  },
  methods: {
    removeFriendModal () {
      this.isModalRemove = true
    },
    openModal () {
      this.isPopupShown = true
    },
    closeModal () {
      this.isPopupShown = false
      this.isModalRemove = false
      this.isAddedFriend = false
    },
    removeFriend (id) {
      this.isModalRemove = false
      this.isRemovedFriend = true
      const getData = `user=${id}`
      this.$https('/remfriends', {
        method: 'POST',
        mode: 'no-cors',
        crossDomain: true,
        headers: {
          // 'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: getData
      }).then(response => {
        this.$emit('update')
        this.isRemovedFriend = false
      })
        .catch(error => {
          console.log('', error)
        })
      this.isLoading = false
      this.closeModal()
    },
    addFriend (id) {
      this.isPopupShown = false
      this.isAddedFriend = true
      const getData = `user=${id}`
      this.$https('/friends', {
        method: 'POST',
        mode: 'no-cors',
        crossDomain: true,
        headers: {
          // 'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: getData
      }).then(response => {
        this.$emit('update')
        this.closeModal()
      })
        .catch(error => {
          console.log('', error)
        })
    }
  }
}
</script>
<style lang="scss" scoped>
  .avatar img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    position: relative;
  }

.bottom-add-sign {
  position: absolute;
  bottom: 0px;
  left: 45%;
  z-index: 2;
  width: 26px;
  height: 26px;
  background-color: #fff;
  border-radius: 50%;
  transform: translate(-13px, 13px);

  &::before {
    position: absolute;
    display: block;
    top: 50%;
    left: 50%;
    z-index: 3;
    width: 24px;
    height: 24px;
    font-size: 24px;
    line-height: 24px;
    color: #000;
    content: '\f055';
    background-color: #fff;
    border-radius: 50%;
    font-weight: 900;
    font-family: 'Font Awesome 5 Free';
    transform: translate(-50%, -50%);
  }
  }

  .bottom-remove-sign {
  position: absolute;
  bottom: 0px;
  left: 45%;
  z-index: 2;
  width: 26px;
  height: 26px;
  background-color: #fff;
  border-radius: 50%;
  transform: translate(-13px, 13px);

  &::before {
    position: absolute;
    display: block;
    top: 50%;
    left: 50%;
    z-index: 3;
    width: 24px;
    height: 24px;
    font-size: 24px;
    line-height: 24px;
    color: #000;
    content: '\f056';
    background-color: #fff;
    border-radius: 50%;
    font-weight: 900;
    font-family: 'Font Awesome 5 Free';
    transform: translate(-50%, -50%);
  }
  }
  .link-avatar {
    color: #000;
  }
  .avatar-placeholder {
    width: 100px;
    height: 100px;
    border: 1px solid black;
    border-radius: 50%;
    font-size: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>
