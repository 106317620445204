<template>
<div>
    <div class="book-card">
      <div class="">
        <router-link :to="{name: 'BooksDetails', params: {id: res.items[0].id}}">
          <div class="book-cover"
          v-for="(item, id) in 1" :key="id">
            <img
              :src="res.items[0].image ? coverUrl + res.items[0].image : coverUrl + 'no_cover.png'"
            />
          <div class="bottom-plus-sign" @click.prevent="addBook(res.items[0].id, res.items[0])"></div>
          </div>
        </router-link>
        <div class="mb-3">
           <router-link class="text-dark" :to="{name: 'PublishingBookDetails', params: {result: res.id, name: res.name, items: res.items}}">
              <span class="book-title">{{ res.items[0].wydawca}}</span>
           </router-link>
          <router-link :to="{name: 'BooksDetails', params: {id: res.items[0].id}}">
            <span class="book-info">{{ res.items[0].tytul }}</span>
          </router-link>
        <div class="stars-wrapper mt-1" v-if=" res.items[0].rating > 0">
          <star-rating :increment="0.5" active-color="#000" :star-size="starSize"
          inactive-color="#F1F1F1" active-border-color="#000" border-color="#000000"
          :border-width='borderWidth' :show-rating="false" :read-only="true"
          :rounded-corners='rounded' :rating="parseFloat(res.items[0].rating, 10)" >
          </star-rating>
        </div>
        <div v-if="res.items[0].rating > 0" class="rating-size text-dark mt-1">Ocena: {{avgRate}}</div>
        <router-link class="text-dark" :to="{name: 'PublishingBookDetails', params: {result: res.id, name: res.name, items: res.items}}">
          <button class="btn-more">Zobacz wszystkie pozycje tego wydawnictwa</button>
        </router-link>
      </div>
        <ModalSlot title="Dodaj książkę" v-if="isPopupShown" @close-modal="closeModal">
          <div class="text-center mb-2 modal-main-title">Dodaj książkę</div>
          <div class="modal-wrapper">
            <div class="growing-slide-cover wrapper-cover w-100">
              <img :src="( cover ? coverUrl + cover : coverUrl + 'no_cover.png')" class="slide-cover-image"/>
              </div>
              <div class="book-heading-big text-center mt-3">{{author}}</div>
              <div class="book-heading-small text-center mt-3">{{title}}</div>
          </div>
          <router-link :to="{name: 'BorrowBook', params: {id: book_id}}"><button class="btn-black-border w-100 mt-3 mb-1">Dodaj na półkę</button></router-link>
          <button class="btn-black-border w-100 mt-3 mb-1" @click="saveToLater">Zapisz na później</button>
          <button class="btn-black-full w-100 mt-3 mb-1" @click="closeModal(false)">Zamknij</button>
        </ModalSlot>
      </div>
  </div>
</div>
</template>
<script>
import StarRating from 'vue-star-rating'
import ModalSlot from '../components/ModalSlot'
export default {
  name: 'SearchResultListPublishing',
  props: {
    res: Object
  },
  components: {
    StarRating,
    ModalSlot
  },
  data () {
    return {
      starsReadOnly: true,
      rating: 1,
      borderWidth: 2,
      animate: true,
      rounded: true,
      starSize: 18,
      isPopupShown: false,
      avgRate: 0
    }
  },
  created () {
    this.avgRate = this.res.items[0].rating
    this.avgRate = parseFloat(this.avgRate, 10)
    if (this.avgRate % 1 === 0) {
      this.avgRate = this.avgRate.toFixed(0)
    } else {
      this.avgRate = this.avgRate.toFixed(1)
    }
  },
  methods: {
    addBook (id, book) {
      this.book_id = id
      this.cover = book.image
      this.author = book.autor
      this.title = book.tytul
      this.isPopupShown = true
    },
    closeModal () {
      this.isPopupShown = false
    },
    saveToLater () {
      const getData = 'status=later&own=1&borrowed=0'
      this.$https('book/' + this.book_id + '/add/' + 0, {
        method: 'POST',
        mode: 'no-cors',
        crossDomain: true,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: getData
      }).then(response => {
        this.closeModal()
      })
        .catch(error => {
          console.log('', error)
        })
    }
  }
}
</script>
<style lang="scss" scoped>
    .btn-more {
    font-size: 13px;
    line-height: 15px;
    margin-top: 5px;
    border: 1px solid black;
    padding: 5px 0;
  }

  @media screen and (max-width: 320px) {
    .btn-more {
      width: 90%;
      font-size: 13px;
    }
  }

  .stars-wrapper {
    display: flex;
    justify-content: flex-start;
  }
  .bottom-plus-sign {
  position: absolute;
  bottom: 0%;
  left: 50%;
  z-index: 2;
  width: 26px;
  height: 26px;
  background-color: #fff;
  border-radius: 50%;
  transform: translate(-13px, 13px);
  z-index: 5;

  &::before {
    position: absolute;
    display: block;
    top: 50%;
    left: 50%;
    z-index: 3;
    width: 26px;
    height: 26px;
    font-size: 26px;
    line-height: 24px;
    color: #000;
    content: '\f055';
    background-color: #fff;
    border-radius: 50%;
    font-weight: 900;
    font-family: 'Font Awesome 5 Free';
    transform: translate(-50%, -50%);
  }
}
  .wrapper-cover {
    display: flex;
    justify-content: center;

    img {
      max-height: 270px;
      max-width: 210px;
    }
  }
  .rating-size {
    font-size: 14px;
  }
</style>
