<template>
<div>
  <router-link :to="{name: 'BooksDetails', params: {id: res.id}}">
    <div class="book-card">
      <div class="book-cover-container">
        <div class="book-cover"
        v-for="(item, id) in 1" :key="id">
          <img
            :src="res.image ? (coverUrl + res.image) : (coverUrl + 'no_cover.png')"
          />
        </div>
        <div class="bottom-plus-sign" @click.prevent="addBook(res.id, res)"><i class="fas fa-plus-circle"></i></div>
      </div>
      <div class="mb-3">
        <a href="" class="book-title">{{ res.autor }}</a>
        <span class="book-info mt-2">{{ res.tytul }}</span>
        <div class="stars-wrapper mt-1" v-if="res.rating > 0">
          <star-rating :increment="0.5" active-color="#000" :star-size="starSize"
          inactive-color="#F1F1F1" active-border-color="#000" border-color="#000000"
          :border-width='borderWidth' :show-rating="false" :read-only="true"
          :rounded-corners='rounded' :rating="parseFloat(res.rating, 10)" >
          </star-rating>
        </div>
        <div v-if="res.rating > 0" class="rating-size text-dark mt-1">Ocena: {{avgRate}}</div>
        <router-link class="text-dark" :to="{name: 'Authors', params: {id: res.author_id}}">
          <button class="btn-more">Zobacz wszystkie pozycje tego autora</button>
        </router-link>
      </div>
  </div>
  </router-link>
    <ModalSlot title="Dodaj książkę" v-if="isPopupShown" @close-modal="closeModal">
      <div class="text-center mb-2 modal-main-title">Dodaj książkę</div>
      <div class="modal-wrapper">
        <div class="growing-slide-cover wrapper-cover w-100">
          <img :src="( cover ? coverUrl + cover : coverUrl + 'no_cover.png')" class="slide-cover-image"/>
          </div>
          <div class="book-heading-big text-center mt-3">{{author}}</div>
          <div class="book-heading-small text-center mt-3">{{title}}</div>
      </div>
      <router-link :to="{name: 'BorrowBook', params: {id: book_id}}"><button class="btn-black-border w-100 mt-3 mb-1">Dodaj na półkę</button></router-link>
      <button class="btn-black-border w-100 mt-3 mb-1" @click="saveToLater">Zapisz na później</button>
       <button class="btn-black-full w-100 mt-3 mb-1" @click="closeModal(false)">Zamknij</button>
    </ModalSlot>
</div>
</template>
<script>
import ModalSlot from '../components/ModalSlot'
import StarRating from 'vue-star-rating'
export default {
  name: 'SearchResultListAuthor',
  props: {
    res: Object
  },
  components: {
    ModalSlot,
    StarRating
  },
  data () {
    return {
      isPopupShown: false,
      book_id: '',
      cover: '',
      author: '',
      title: '',
      avgRate: 0,
      starsReadOnly: true,
      rating: 1,
      borderWidth: 2,
      animate: true,
      rounded: true,
      starSize: 18
    }
  },
  created () {
    this.avgRate = parseFloat(this.res.rating, 10)
    if (this.avgRate % 1 === 0) {
      this.avgRate = this.avgRate.toFixed(0)
    } else {
      this.avgRate = this.avgRate.toFixed(1)
    }
  },
  methods: {
    addBook (id, book) {
      this.book_id = id
      this.cover = book.image
      this.author = book.autor
      this.title = book.tytul
      this.isPopupShown = true
    },
    closeModal () {
      this.isPopupShown = false
    },
    saveToLater () {
      const getData = 'status=later&own=1&borrowed=0'
      this.$https('book/' + this.book_id + '/add/' + 0, {
        method: 'POST',
        mode: 'no-cors',
        crossDomain: true,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: getData
      }).then(response => {
        this.closeModal()
      })
        .catch(error => {
          console.log('', error)
        })
    }
  }
}
</script>
<style lang="scss" scoped>
  .wrapper-cover {
    display: flex;
    justify-content: center;

    img {
      max-height: 270px;
      max-width: 210px;
    }
  }
  .btn-more {
    font-size: 13px;
    line-height: 15px;
    margin-top: 5px;
    border: 1px solid black;
    padding: 5px 0;
  }

@media screen and (max-width: 320px) {
  .btn-more {
    width: 90%;
    font-size: 13px;
  }
}

  .stars-wrapper {
    display: flex;
    justify-content: flex-start;
  }
  .rating-size {
    font-size: 14px;
  }
</style>
