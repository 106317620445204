<template>
  <div class="image-wrapper">
    <div>
      <img src="../assets/loaders/loader_on_overlay.gif" class="image-placeholder"/>
    </div>
  </div>
</template>
<script>
export default {
  name: 'SearchingPlaceholder'
}
</script>
<style lang="scss" scoped>
.image-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 400px;
}
.image-placeholder {
  width: 75px;
  height: 75px;
}
</style>
