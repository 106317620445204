<template>
  <div>
    <div class="pt-5 text-center top-heading-big">Ups! Brak wyników dla wyszukiwanej frazy</div>
    <div class="pt-5 text-center top-heading-medium">Sprawdź czy szukana fraza wpisana jest prawidłowo lub wyszukaj inną</div>
  </div>
</template>
<script>
export default {
  name: 'SearchResultEmpty'
}
</script>
<style lang="scss" scoped>

</style>
